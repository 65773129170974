<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          :to="getLinkHome"
        >
          <b-img
            v-if="empresaLogo"
            :src="empresaLogo"
            alt="logo"
          />
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { getLinkHome } from '@/auth/utils'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BLink,
    BImg,
  },

  data() {
    return {
      getLinkHome,
    }
  },

  setup() {
    const { empresa, empresaLogo } = useAppConfig()
    const { appName, appLogoImage } = $themeConfig.app

    return {
      appName,
      appLogoImage,
      empresaLogo,
      empresa,
    }
  },
}
</script>

<style>

</style>
