export default [
  {
    title: 'Ativação',
    route: 'ativacao',
    resource: 'pendente',
  },
  {
    title: 'Tutoriais',
    route: 'tutorial',
    icon: 'BookOpenIcon',
    resource: 'pendente',
  },
]
