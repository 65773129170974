<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import { getContext } from '@/auth/utils'
import navMenuItemsSpot from '@/navigation/horizontal/index-spot'
import navMenuItemsFutures from '@/navigation/horizontal/index-futures'
import navMenuItemsGeral from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },

  setup() {
    let navMenuItems = navMenuItemsGeral

    if (getContext() === 'futures') {
      navMenuItems = navMenuItemsFutures
    } else if (getContext() === 'spot') {
      navMenuItems = navMenuItemsSpot
    }

    return {
      navMenuItems,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
