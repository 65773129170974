export default [
  {
    title: 'Início',
    icon: 'HomeIcon',
    route: 'home',
    resource: 'assinante',
  },
  {
    title: 'Compras abertas',
    icon: 'ShoppingCartIcon',
    route: 'spot-compra-aberta',
    resource: 'assinante',
  },
  {
    title: 'Vendas abertas',
    icon: 'TrendingUpIcon',
    route: 'spot-venda-aberta',
    resource: 'assinante',
  },
  {
    title: 'Câmbio',
    icon: 'CreditCardIcon',
    route: 'spot-cambio',
    resource: 'assinante',
  },
  {
    header: 'Relatórios',
    icon: 'ListIcon',
    children: [
      {
        title: 'Fechamento',
        route: 'spot-relatorio-fechamento',
        resource: 'assinante',
      },
      {
        title: 'Sinais',
        route: 'spot-relatorio-sinal',
        resource: 'assinante',
      },
    ],
  },
  {
    title: 'Meus agentes',
    icon: 'UsersIcon',
    route: 'spot-meu-agente',
    resource: 'assinante',
  },
  {
    header: 'Agente',
    icon: 'UserIcon',
    children: [
      {
        title: 'Nova operação',
        route: 'spot-agente-nova-operacao',
        resource: 'agente',
      },
      {
        title: 'Operações abertas',
        route: 'spot-agente-operacao-aberta',
        resource: 'agente',
      },
      {
        title: 'Configurações',
        route: 'spot-agente-configuracao',
        resource: 'agente',
      },
      {
        title: 'Monitorar operações',
        route: 'spot-monitor-operador-estrategia-instancias',
        resource: 'agente-robo',
      },
    ],
  },
  {
    title: 'Ajustes',
    icon: 'SettingsIcon',
    route: 'conta-configuracao',
    resource: 'assinante',
  },
  {
    header: 'Indicações',
    icon: 'Share2Icon',
    children: [
      {
        title: 'Dashboard',
        route: 'indicacao',
        resource: 'multinivel',
      },
      {
        title: 'Extrato',
        route: 'indicacao-extrato',
        resource: 'multinivel',
      },
      {
        title: 'Rede',
        route: 'indicacao-rede',
        resource: 'multinivel',
      },
      {
        title: 'Saque',
        route: 'indicacao-saque',
        resource: 'multinivel',
      },
    ],
  },
  {
    header: 'Controle',
    icon: 'UserIcon',
    children: [
      {
        title: 'Assinantes',
        route: 'controle-assinante',
        resource: 'administrador',
      },
    ],
  },
]
